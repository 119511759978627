<template>
  <div
    v-cloak
    class="mshe-container mshe-top-pad mshe-text-center"
  >
    <v-header />
    <div>
      <iframe
        :src="iframeSrc"
        scrolling="no"
        frameborder="0"
        style="width: 100%;max-width: 1400px;height: 705px;"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import vHeader from '../components/header'
import { mapMutations } from 'vuex'

const { IS_RW } = window.gbCommonInfo

export default defineComponent({
  name: 'PageContainer',
  components: {
    vHeader,
  },
  data() {
    return {
      IS_RW
    }
  },
  computed: {
    iframeSrc() {
      return `https://www.myunidays.com/partners/${IS_RW ? 'romwe' : 'shein'}/embedded/online`
    }
  },
  mounted() {
    this.handleHeaderTitle()
  },
  methods: {
    ...mapMutations('root', ['changeHeaderNav']),
    handleHeaderTitle() {
      const route = this.$route
      this.changeHeaderNav({ title: '' })
      if (route.meta && route.meta.navTitle) {
        this.changeHeaderNav({
          title: route.meta.navTitle,
          fromName: ''
        })
      }
    }
  },
})
</script>
